import { Card, Modal } from 'antd';
import type { PaginationData } from 'egenie-common';
import { NormalProgrammeComponent, NormalProgramme, request, ImgFormatter, MainSubStructure, MainSubStructureModel } from 'egenie-utils';
import _ from 'lodash';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import type { Store } from './store';
import type { OutOfStockStatisticsItem } from './types';

interface OutOfStockStatisticsModalProps {
  outOfStockStatisticsModalStore: OutOfStockStatisticsModalStore;
}

let id = 0;

export class OutOfStockStatisticsModalStore {
  constructor(parent: Store) {
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
    this.parent = parent;
  }

  private readonly parent: Store;

  @observable public visible = false;

  @action public handleOpen = () => {
    this.mainSubStructureModel.gridModel.resetAll();
    this.normalProgramme.reset();
    this.visible = true;
    this.mainSubStructureModel.onQuery();
  };

  @action public handleClose = () => {
    this.mainSubStructureModel.gridModel.resetAll();
    this.normalProgramme.reset();
    this.visible = false;
  };

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'vendor',
          name: '供应商编码',
          width: 200,
          sortable: true,
        },
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'productNo',
          name: '商品编码',
          width: 200,
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 200,
          sortable: true,
        },
        {
          key: 'color',
          name: '颜色',
          width: 80,
        },
        {
          key: 'size',
          name: '尺码',
          width: 80,
        },
        {
          key: 'sellerOuterNo',
          name: '货号',
          width: 200,
        },
        {
          key: 'owner',
          name: '货主',
          width: 200,
        },
        {
          key: 'stockOutNumber',
          name: '缺货数量',
          width: 80,
          sortable: true,
        },
        {
          key: 'stockOutDay',
          name: '缺货天数',
          width: 80,
          sortable: true,
        },
        {
          key: 'outStockReason',
          name: '缺货原因',
          width: 100,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: '_id',
      sortByLocal: false,
      showCheckBox: true,
      showReset: true,
      showEmpty: false,
      pageSize: 200,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/purchaseOrder/index/outOfStockStatisticsModal',
    },
    buttons: [
      {
        text: '导出',
        icon: 'icon-export',
        handleClick: () => {
          // ownerId + '_' + skuId
          const selectIds: string[] = this.mainSubStructureModel.gridModel.selectRows.map((item) => `${_.toString(item.ownerId)}_${_.toString(item.skuId)}`)
            .filter(Boolean);
          const ids = Array.from(new Set(selectIds))
            .join(',');
          const fileName = '缺货统计';
          const exportType = 'clous_wms_out_of_stock';
          const queryParam = this.normalProgramme.filterItems.params;
          const queryParamShow = this.normalProgramme.filterItems.translateParams.join(' ');

          if (ids) {
            this.parent.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
          } else {
            Modal.confirm({
              title: '提示',
              content: '未选择数据将导出全部数据?',
              onOk: () => {
                this.parent.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
              },
            });
          }
        },
      },
    ],
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          sidx,
          sord,
          ...rest
        } = params;

        return request<PaginationData<OutOfStockStatisticsItem[]>>({
          url: '/api/cloud/wms/rest/out/of/stock/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
            sidx: sidx || 'stockOutDay',
            sord: sord || 'desc',
          },
        })
          .then((info) => ({
            ...info,
            data: {
              ...info.data,
              list: (info.data.list || []).map((item) => ({
                ...item,
                _id: id++,
              })),
            },
          }));
      },
    },
    hiddenSubTable: true,
  });

  public normalProgramme: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'skuNo',
        label: 'SKU编码',
      },
      {
        type: 'input',
        field: 'productNo',
        label: '商品编码',
      },
      {
        type: 'select',
        field: 'vendorIds',
        label: '供应商',
        mode: 'multiple',
      },
      {
        type: 'select',
        field: 'ownerIds',
        label: '货主',
        mode: 'multiple',
      },
    ],
    count: 6,
    handleSearch: () => this.mainSubStructureModel.onQuery(),
  });
}

@observer
export class OutOfStockStatisticsModal extends Component<OutOfStockStatisticsModalProps> {
  render() {
    const {
      outOfStockStatisticsModalStore: {
        handleClose,
        visible,
        normalProgramme,
        mainSubStructureModel,
      },
    } = this.props;
    return (
      <Modal
        centered
        destroyOnClose
        footer={null}
        maskClosable={false}
        onCancel={handleClose}
        title="缺货统计"
        visible={visible}
        width={1560}
      >
        <Card size="small">
          <NormalProgrammeComponent store={normalProgramme}/>
        </Card>
        <div style={{ height: 540 }}>
          <MainSubStructure store={mainSubStructureModel}/>
        </div>
      </Modal>
    );
  }
}

