import type { PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { TimeStampFormatter } from 'egenie-utils';
import React from 'react';
import type { Store } from './store';
import type { ParcelCodeItem } from './types';

export function purchaseOrderParcelDetail(context: Store) {
  return {
    tab: {
      name: '包裹码',
      value: 'parcel',
    },
    grid: {
      columns: [
        {
          key: 'packageCodeNo',
          name: '包裹码编号',
          width: 200,
        },
        {
          key: 'receiveStateDesc',
          name: '收货状态',
          width: 80,
        },
        {
          key: 'createTime',
          name: '创建时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.createTime}/>,
        },
        {
          key: 'ownerName',
          name: '货主',
          width: 150,
        },
        {
          key: 'sendTotalNum',
          name: '送货总数量',
          width: 100,
        },
        {
          key: 'receiveTotalNum',
          name: '收货总数量',
          width: 100,
        },
        {
          key: 'printCount',
          name: '打印次数',
          width: 100,
        },
        {
          key: 'printStateDesc',
          name: '打印状态',
          width: 100,
        },
        {
          key: 'vendorPrintDesc',
          name: '供应商贴码',
          width: 100,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'cloudPackageCodeId',
      showCheckBox: false,
    },
    api: {
      onQuery: ({
        data,
        pid,
      }) => {
        const {
          sidx,
          sord,
          page,
          pageSize,
        } = data;
        return request<PaginationData<ParcelCodeItem>>({
          url: '/api/cloud/pms/package/code/list/by/purchase/id',
          method: 'POST',
          data: {
            sidx,
            sord,
            page,
            pageSize,
            id: pid,
          },
        });
      },
    },
  };
}
