import { Card, InputNumber, message, Modal } from 'antd';
import type { BaseData, PaginationData } from 'egenie-common';
import { formatNumber, printHelper, getCustomPrintParam, request } from 'egenie-common';
import { ImgFormatter, MainSubStructure, MainSubStructureModel, TimeStampFormatter, NormalProgrammeComponent, NormalProgramme } from 'egenie-utils';
import { action, observable } from 'mobx';
import { observer, Observer } from 'mobx-react';
import React, { Component } from 'react';
import type { UnicodeReprintItem } from './types';

interface PagePrintParams {
  page: number;
  pageSize: number;
  tempId: string | number;
  tempType: string | number;
  preview: boolean;
  printer?: string;
  purchaseOrderSkuList: UnicodeReprintItem[];
  uuid?: string;
}

async function pagePrint(data: PagePrintParams) {
  const printData = await request<BaseData<{ uuid?: string; userDataList: any; tempData: any; pageData: { page: number; pageSize: number; totalCount: number; totalPageCount: number; }; }>>({
    url: '/api/cloud/pms/print/reprintUniqueCodeData',
    method: 'POST',
    timeout: 1000 * 1800,
    data,
  });

  if (Array.isArray(printData?.data?.userDataList) && printData?.data?.userDataList.length) {
    printHelper.toggleToLodop();
    await printHelper.print({
      preview: data.preview,
      printer: data.printer,
      templateData: printData?.data?.tempData,
      contents: printData?.data?.userDataList,
      count: 500,
    });
  }

  if (printData?.data?.pageData?.totalPageCount && data.page < printData?.data?.pageData?.totalPageCount) {
    await pagePrint({
      ...data,
      uuid: data.uuid || printData?.data?.uuid,
      page: data.page + 1,
    });
  }
}

export class UnicodeReprintModalStore {
  constructor() {
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
  }

  @observable public visible = false;

  @action public handleOpen = () => {
    this.mainSubStructureModel.gridModel.resetAll();
    this.normalProgramme.reset();
    this.visible = true;
    this.mainSubStructureModel.onQuery();
  };

  @action public handleClose = () => {
    this.mainSubStructureModel.gridModel.resetAll();
    this.normalProgramme.reset();
    this.visible = false;
  };

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'vendorName',
          name: '供应商编码',
          width: 200,
          sortable: true,
          sidx: 'vendor_name',
        },
        {
          key: 'ownerUniqueName',
          name: '货主',
          width: 200,
        },
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'productNo',
          name: '商品编码',
          width: 200,
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 200,
          sortable: true,
          sidx: 'sku_no',
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
        },
        {
          key: 'vendorProductNo',
          name: '货号',
          width: 100,
        },
        {
          key: 'planPurchaseNum',
          name: '计划采购数量',
          width: 120,
        },
        {
          key: 'printNum',
          name: '打印数量',
          width: 80,
          formatter: ({ rowIdx }: { rowIdx: number; }) => {
            return (
              <Observer>
                {
                  () => (
                    <InputNumber
                      max={9999}
                      min={1}
                      onChange={(value) => this.mainSubStructureModel.gridModel.rows[rowIdx].printNum = formatNumber(value)}
                      onClick={(event) => event.stopPropagation()}
                      size="small"
                      style={{ width: '100%' }}
                      value={this.mainSubStructureModel.gridModel.rows[rowIdx].printNum}
                    />
                  )
                }
              </Observer>
            );
          },
        },
        {
          key: 'cloudPmsPurchaseOrderNo',
          name: '采购单号',
          width: 200,
        },
        {
          key: 'cloudPmsPurchaseOrderCreateTime',
          name: '采购日期',
          width: 200,
          formatter: ({ row }) => <TimeStampFormatter value={row.cloudPmsPurchaseOrderCreateTime}/>,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'cloudPmsPurchaseOrderDetailId',
      sortByLocal: false,
      showCheckBox: true,
      showReset: true,
      showEmpty: true,
      pageSize: 200,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/purchaseOrder/index/unicodeReprintModal',
    },
    buttons: [
      {
        text: '打印唯一码',
        icon: 'icon-d_print',
        handleClick: async() => {
          const selectMainRows: UnicodeReprintItem[] = this.mainSubStructureModel.gridModel.selectRows;
          if (selectMainRows.length === 0) {
            message.error('请至少选择一条数据');
            return;
          }

          const {
            templateId,
            tempType,
            printer,
            preview,
          } = await getCustomPrintParam('27');
          await pagePrint({
            page: 1,
            pageSize: 1000,
            purchaseOrderSkuList: selectMainRows.map((item) => ({
              cloudPmsPurchaseOrderDetailId: item.cloudPmsPurchaseOrderDetailId,
              cloudPmsPurchaseOrderId: item.cloudPmsPurchaseOrderId,
              planPurchaseNum: item.planPurchaseNum,
              printNum: formatNumber(item.printNum) || 1,
              skuId: item.skuId,
            })),
            tempId: templateId,
            tempType,
            preview,
            printer,
          });

          this.mainSubStructureModel.gridModel.resetAll();
          this.mainSubStructureModel.gridModel.onRefresh();
        },
      },
    ],
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          sidx,
          sord,
          ...rest
        } = params;

        return request<PaginationData<UnicodeReprintItem>>({
          url: '/api/cloud/pms/purchaseOrder/barcodeToPrint/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
            sidx: sidx || 'vendor_name',
            sord: sord || 'desc',
          },
        });
      },
    },
    hiddenSubTable: true,
  });

  public normalProgramme: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'skuNo',
        label: 'SKU编码',
      },
      {
        type: 'input',
        field: 'productNo',
        label: '商品编码',
      },
      {
        type: 'input',
        field: 'cloudPmsPurchaseOrderNo',
        label: '采购单号',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'ownerIds',
        label: '货主',
        isParamList: true,
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'vendorIds',
        label: '供应商',
        isParamList: true,
      },
    ],
    count: 6,
    handleSearch: () => this.mainSubStructureModel.onQuery(),
  });
}

@observer
export class UnicodeReprintModal extends Component<{ unicodeReprintStore: UnicodeReprintModalStore; }> {
  render() {
    const {
      unicodeReprintStore: {
        handleClose,
        visible,
        normalProgramme,
        mainSubStructureModel,
      },
    } = this.props;
    return (
      <Modal
        centered
        destroyOnClose
        maskClosable={false}
        onCancel={handleClose}
        title="补打条码"
        visible={visible}
        width={1400}
      >
        <Card size="small">
          <NormalProgrammeComponent store={normalProgramme}/>
        </Card>
        <div style={{ height: 540 }}>
          <MainSubStructure store={mainSubStructureModel}/>
        </div>
      </Modal>
    );
  }
}

