import type { FilterItemOptions } from 'egenie-utils';
import { enumPushStatus } from './constants';

export function mainFilterItems(): FilterItemOptions[] {
  return [
    {
      type: 'date',
      field: 'dateType',
      label: '日期类型',
      selectValue: 'createTime',
      data: [
        {
          value: 'createTime',
          label: '创建时间',
        },
        {
          value: 'checkTime',
          label: '审核时间',
        },
        {
          value: 'closeTime',
          label: '关闭时间',
        },
      ],
    },
    {
      type: 'input',
      label: '采购单号',
      field: 'cloudPmsPurchaseOrderNo',
    },
    {
      type: 'input',
      label: 'SKU编码',
      field: 'skuNo',
    },
    {
      type: 'checkbox',
      label: '单据状态',
      field: 'purchaseOrderStatus',
      data: [],
      isParamList: true,
    },
    {
      type: 'select',
      mode: 'multiple',
      label: '供应商',
      field: 'vendorId',
      isParamList: true,
    },
    {
      type: 'select',
      label: '仓库',
      field: 'warehouseId',
      data: [],
    },
    {
      type: 'select',
      label: '创建人',
      field: 'creator',
      data: [],
    },
    {
      type: 'select',
      label: '审核人',
      field: 'auditorId',
      data: [],
    },
    {
      type: 'input',
      label: '备注',
      field: 'remarks',
    },
    {
      type: 'select',
      label: '采购来源',
      field: 'purchaseOrderOriginType',
      data: [],
    },
    {
      type: 'select',
      label: '货主',
      field: 'ownerId',
      mode: 'multiple',
      isParamList: true,
    },

    {
      type: 'select',
      label: '关闭状态',
      field: 'close',
      data: [
        {
          value: '0',
          label: '未关闭',
        },
        {
          value: '1',
          label: '已关闭',
        },
      ],
    },
    {
      type: 'select',
      label: '发布供应商',
      field: 'pushStatus',
      data: Object.values(enumPushStatus),
    },
    {
      type: 'select',
      label: '采购单类型',
      field: 'purchaseOrderType',
      mode: 'multiple',
      isParamList: true,
    },
    {
      type: 'input',
      label: '原采购单号',
      field: 'sourcePmsPurchaseOrderNo',
    },
  ];
}
