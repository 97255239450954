import { Modal, Radio } from 'antd';
import type { PaginationData } from 'egenie-utils';
import { MainSubStructure, MainSubStructureModel, TimeStampFormatter, request } from 'egenie-utils';
import { action, computed, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { Store } from './store';
import type { PurchaseSkuDetailItem } from './types';

let id = 0;

const enumTabType = {
  unicode: {
    value: 'unicode',
    label: '唯一码详情',
  },
  order: {
    value: 'order',
    label: '订单详情',
  },
};

export class UniqueCodeAndOrderDetailModel {
  @observable public tabType: string = enumTabType.unicode.value;

  @action public handleTabTypeChange = (event) => {
    this.tabType = event.target.value;
    this.mainSubStructureModel.gridModel.resetAll();
    this.mainSubStructureModel.gridModel.onQuery();
  };

  @observable.ref public purchaseSkuDetailItem: PurchaseSkuDetailItem = {};

  @observable public visible = false;

  @computed public get mainSubStructureModel() {
    return this.tabType === enumTabType.unicode.value ? this.uniqueCodeMainSubStructureModel : this.orderMainSubStructureModel;
  }

  public uniqueCodeMainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'skuNo',
          name: 'sku编码',
          width: 200,
        },
        {
          key: 'uniqueCode',
          name: '唯一码',
          width: 200,
        },
        {
          key: 'uniqueCodeStatusName',
          name: '唯一码状态',
          width: 120,
        },
        {
          key: 'lastPrintTime',
          name: '打印时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.lastPrintTime}/>,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: '_id',
      showCheckBox: false,
      showReset: false,
      showSelectedTotal: false,
    },
    api: {
      onQuery: (params) => {
        const {
          filterParams,
          ...rest
        } = params;
        return request<PaginationData<any>>({
          method: 'POST',
          url: '/api/cloud/pms/purchaseOrderDetail/uniqueCode/query',
          data: {
            cloudPmsPurchaseOrderDetailId: this.purchaseSkuDetailItem.cloudPmsPurchaseOrderDetailId,
            cloudPmsPurchaseOrderId: this.purchaseSkuDetailItem.cloudPmsPurchaseOrderId,
            skuNo: this.purchaseSkuDetailItem.skuNo,
            ...rest,
          },
        })
          .then((info) => ({
            ...info,
            data: {
              ...info?.data,
              list: (info.data?.list || []).map((item) => ({
                ...item,
                _id: id++,
              })),
            },
          }));
      },
    },
    hiddenSubTable: true,
  });

  public orderMainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'platformOrderCode',
          name: '平台单号',
          width: 200,
        },
        {
          key: 'payTime',
          name: '付款时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.payTime}/>,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: '_id',
      showCheckBox: false,
      showReset: false,
      showSelectedTotal: false,
    },
    api: {
      onQuery: (params) => {
        const {
          filterParams,
          ...rest
        } = params;
        return request<PaginationData<any>>({
          method: 'POST',
          url: '/api/cloud/pms/purchaseOrderDetail/sale/order',
          data: {
            cloudPmsPurchaseOrderDetailId: this.purchaseSkuDetailItem.cloudPmsPurchaseOrderDetailId,
            cloudPmsPurchaseOrderId: this.purchaseSkuDetailItem.cloudPmsPurchaseOrderId,
            ...rest,
          },
        })
          .then((info) => ({
            ...info,
            data: {
              ...info?.data,
              list: (info.data?.list || []).map((item) => ({
                ...item,
                _id: id++,
              })),
            },
          }));
      },
    },
    hiddenSubTable: true,
  });

  @action public handleCancel = () => {
    this.visible = false;
    this.purchaseSkuDetailItem = {};
    this.mainSubStructureModel.gridModel.resetAll();
    this.tabType = enumTabType.unicode.value;
  };

  @action public handleOpen = (purchaseSkuDetailItem: PurchaseSkuDetailItem) => {
    this.visible = true;
    this.purchaseSkuDetailItem = toJS(purchaseSkuDetailItem);
    this.mainSubStructureModel.gridModel.resetAll();
    this.mainSubStructureModel.gridModel.onQuery();
  };
}

@observer
export class UniqueCodeAndOrderDetailModal extends React.Component<{ store: Store; }> {
  render() {
    const {
      store: {
        uniqueCodeAndOrderDetailModel: {
          handleCancel,
          visible,
          mainSubStructureModel,
          purchaseSkuDetailItem,
          tabType,
          handleTabTypeChange,
        },
      },
    } = this.props;
    return (
      <Modal
        centered
        footer={null}
        onCancel={handleCancel}
        title={purchaseSkuDetailItem.purchaseOrderType === 0 ? (
          <Radio.Group
            onChange={handleTabTypeChange}
            optionType="button"
            options={Object.values(enumTabType)}
            value={tabType}
          />
        ) : '唯一码详情'}
        visible={visible}
        width={900}
      >
        <div className={styles.egGrid}>
          <MainSubStructure store={mainSubStructureModel}/>
        </div>
      </Modal>
    );
  }
}
