import { message, Modal } from 'antd';
import type { BaseData, BatchReportData } from 'egenie-common';
import { destroyAllModal, getCustomPrintParam, printHelper, renderModal, request } from 'egenie-common';
import { BatchReport } from 'egenie-utils';
import { values } from 'mobx';
import React from 'react';
import { ProducePurchaseOrder } from './producePurchaseOrder';
import type { Store } from './store';
import type { MainItem, PurchaseSkuDetailItem, PurchaseVendorDetailItem } from './types';

interface PagePrintParams {
  page: number;
  pageSize: number;
  tempId: string | number;
  tempType: string | number;
  purchaseOrderIds: string[];
  purchaseOrderDetailIds: string[];
  purchaseOrderVendorIds?: string[];
  preview: boolean;
  printer?: string;
  uuid?: string;
  printByVendor?: number;
}

export async function pagePrint(data: PagePrintParams) {
  const printData = await request<BaseData<{ uuid?: string; userDataList: any; tempData: any; pageData: { page: number; pageSize: number; totalCount: number; totalPageCount: number; }; }>>({
    url: '/api/cloud/pms/print/uniqueCodeData',
    method: 'POST',
    timeout: 1000 * 1800,
    data,
  });

  if (Array.isArray(printData?.data?.userDataList) && printData?.data?.userDataList.length) {
    printHelper.toggleToLodop();
    await printHelper.print({
      preview: data.preview,
      printer: data.printer,
      templateData: printData?.data?.tempData,
      contents: printData?.data?.userDataList,
      count: 500,
    });
  }

  if (printData?.data?.pageData?.totalPageCount && data.page < printData?.data?.pageData?.totalPageCount) {
    await pagePrint({
      ...data,
      uuid: data.uuid || printData?.data?.uuid,
      page: data.page + 1,
    });
  }
}

function notifyOperateResult(info: BatchReportData) {
  renderModal(
    <BatchReport
      {...info.data}
      columns={[
        {
          title: '采购单号',
          dataIndex: 'orderNo',
        },
        {
          title: '失败原因',
          dataIndex: 'operationResult',
        },
      ]}
    />
  );
}

export function mainButton(context: Store) {
  return [
    {
      permissionId: '702',
      text: '缺货采购单',
      handleClick: () => {
        renderModal(
          <ProducePurchaseOrder
            callback={(async(params) => {
              const req = await request<BaseData>({
                method: 'POST',
                url: '/api/cloud/pms/purchaseOrder/generateOosPurchaseOrder',
                data: {
                  ownerIds: (params.ownerIds || []).join(','),
                  type: 1,
                },
              });

              message.success(`${req.info}`);
              context.programme.gridModel.gridModel.resetAll();
              context.programme.gridModel.gridModel.onRefresh();

              destroyAllModal();
            })}
            onCancel={destroyAllModal}
            title="生成缺货采购订单"
          />
        );
      },
      group: [
        {
          permissionId: '702',
          text: '选款缺货采购单',
          handleClick: () => {
            Modal.confirm({
              content: '确定生成选款缺货采购单吗?',
              onOk: async() => {
                const req = await request<BaseData>({
                  method: 'POST',
                  url: '/api/cloud/pms/purchaseOrder/generateOosPurchaseOrder',
                  data: { type: 2 },
                });

                message.success(`${req.info}`);
                context.programme.gridModel.gridModel.resetAll();
                context.programme.gridModel.gridModel.onRefresh();
              },
            });
          },
          icon: 'icon-btn_xj',
        },
      ],
      icon: 'icon-btn_xj',
    },
    {
      text: '发布至供应商',
      icon: 'icon-btn_xj',
      permissionId: '4013',
      handleClick: () => {
        const rows: MainItem[] = context.programme.gridModel.gridModel.selectRows;
        if (rows.length < 1) {
          const error = '请选择一条或多条数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        Modal.confirm({
          content: '确定发布至供应商吗?',
          onOk: async() => {
            await request({
              method: 'POST',
              url: '/api/cloud/pms/purchaseOrder/pushPurchase',
              data: { cloudPmsPurchaseOrderIds: rows.map((item) => item.cloudPmsPurchaseOrderId) },
            });

            message.success('发布至供应商成功');
            context.programme.gridModel.gridModel.resetAll();
            context.programme.gridModel.gridModel.onRefresh();
          },
        });
      },
    },
    {
      permissionId: '21',
      text: '审核',
      handleClick: () => {
        const rows: MainItem[] = context.programme.gridModel.gridModel.selectRows;
        if (rows.length === 0) {
          const error = '请选择一条或多条数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (!rows.every((item) => item.purchaseOrderStatus == 0)) {
          const error = '只能选择未审核的数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        Modal.confirm({
          content: '确定要审核吗?',
          onOk: async() => {
            const info = await request<BatchReportData<{ orderNo: string; operationResult?: string; }>>({
              timeout: 1000 * 60 * 10,
              method: 'POST',
              url: '/api/cloud/pms/purchaseOrder/check',
              data: {
                ids: rows.map((item) => item.cloudPmsPurchaseOrderId)
                  .join(','),
              },
            });

            notifyOperateResult(info);
            context.programme.gridModel.gridModel.resetAll();
            context.programme.gridModel.gridModel.onRefresh();
          },
        });
      },
      icon: 'icon-btn_xj',
    },
    {
      permissionId: '84',
      text: '关闭',
      handleClick: (e) => {
        const rows: MainItem[] = context.programme.gridModel.gridModel.selectRows;

        if (rows.length === 0) {
          const error = '请选择数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (!rows.every((item) => item.close == 0)) {
          const error = '只能选择未关闭的数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        Modal.confirm({
          content: '确定要关闭吗?',
          onOk: async() => {
            const info = await request<BatchReportData<{ orderNo: string; operationResult?: string; }>>({
              timeout: 120000,
              method: 'POST',
              url: '/api/cloud/pms/purchaseOrder/close',
              data: {
                ids: rows.map((item) => item.cloudPmsPurchaseOrderId)
                  .join(','),
              },
            });

            notifyOperateResult(info);
            context.programme.gridModel.gridModel.resetAll();
            context.programme.gridModel.gridModel.onRefresh();
          },
        });
      },
      icon: 'icon-btn_xj',
    },
    {
      permissionId: '84',
      text: '反关闭',
      handleClick: (e) => {
        const rows: MainItem[] = context.programme.gridModel.gridModel.selectRows;

        if (rows.length === 0) {
          const error = '请选择数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (!rows.every((item) => item.close == 1)) {
          const error = '只能选择关闭的数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        Modal.confirm({
          content: '确定要反关闭吗?',
          onOk: async() => {
            const info = await request<BatchReportData<{ orderNo: string; operationResult?: string; }>>({
              timeout: 120000,
              method: 'POST',
              url: '/api/cloud/pms/purchaseOrder/unclose',
              data: {
                ids: rows.map((item) => item.cloudPmsPurchaseOrderId)
                  .join(','),
              },
            });

            notifyOperateResult(info);
            context.programme.gridModel.gridModel.resetAll();
            context.programme.gridModel.gridModel.onRefresh();
          },
        });
      },
      icon: 'icon-btn_xj',
    },
    {
      permissionId: '2034',
      text: '打印唯一码',
      icon: 'icon-d_print',
      handleClick: async() => {
        let selectMainRows: MainItem[] = context.programme.gridModel.gridModel.selectRows;
        let selectSubRows: PurchaseSkuDetailItem[] | PurchaseVendorDetailItem[] = [];
        let purchaseOrderDetailIds: string[] = [];
        let purchaseOrderVendorIds: string[] = [];

        if (context.programme.gridModel.subTablesModel.activeTab === 'detail') {
          selectSubRows = context.programme.gridModel.subTablesModel.listModel.find((item) => item.tab.value === 'detail').gridModel.selectRows || [];
          purchaseOrderDetailIds = selectSubRows.map((item) => item.cloudPmsPurchaseOrderDetailId);
        } else if (context.programme.gridModel.subTablesModel.activeTab === 'vendor') {
          selectSubRows = context.programme.gridModel.subTablesModel.listModel.find((item) => item.tab.value === 'vendor').gridModel.selectRows || [];
          purchaseOrderVendorIds = selectSubRows.map((item) => item.cloudPmsPurchaseOrderVendorId);
        }

        if (!((selectMainRows.length <= 1 && selectSubRows.length > 0) || (selectMainRows.length >= 1 && selectSubRows.length === 0))) {
          const error = '请勾选主表或子表数据进行打印';
          message.error({
            key: error,
            content: error,
          });
          throw new Error(error);
        }

        // @ts-ignore
        selectMainRows = selectSubRows.length === 0 ? selectMainRows : [context.programme.gridModel.gridModel.cursorRow];
        const purchaseOrderIds = selectMainRows.map((item) => item.cloudPmsPurchaseOrderId);
        const isPrintedMain = selectMainRows.length && selectMainRows.some((item) => item.printTime > 0);
        const isPrintedSub = selectSubRows.length && selectSubRows.some((item) => item.printTime > 0);

        if (isPrintedMain || isPrintedSub) {
          await new Promise((resolve, reject) => {
            Modal.confirm({
              content: 'SKU已经被打印过,多打会造成唯一码重复是否继续',
              onOk: () => resolve(void 0),
              onCancel: () => reject(),
            });
          });
        }

        const {
          templateId,
          tempType,
          printer,
          preview,
        } = await getCustomPrintParam('27');
        await pagePrint({
          page: 1,
          pageSize: 1000,
          tempId: templateId,
          tempType,
          preview,
          printer,
          purchaseOrderIds,
          purchaseOrderDetailIds,
          purchaseOrderVendorIds,
        });

        context.programme.gridModel.onRefresh();
        if (context.programme.gridModel.subTablesModel.activeTab === 'detail') {
          context.programme.gridModel.subTablesModel.listModel.find((item) => item.tab.value === 'detail').gridModel.onRefresh();
        } else if (context.programme.gridModel.subTablesModel.activeTab === 'vendor') {
          context.programme.gridModel.subTablesModel.listModel.find((item) => item.tab.value === 'vendor').gridModel.onRefresh();
        }
      },
      group: [
        {
          permissionId: '4055',
          text: '补打条码',
          icon: 'icon-d_print',
          handleClick: (e) => {
            context.unicodeReprintModalStore.handleOpen();
          },
        },
        {
          permissionId: '4056',
          text: '按供应商打印',
          icon: 'icon-d_print',
          handleClick: (e) => {
            const rows: MainItem[] = context.programme.gridModel.gridModel.selectRows;

            if (rows.length === 0) {
              const error = '请选择数据';
              message.warning({
                key: error,
                content: error,
              });
              return;
            }

            if (rows.some((item) => item.close == 1 || item.purchaseOrderStatus == 0)) {
              const error = '仅可选择未关闭已审核的采购单';
              message.warning({
                key: error,
                content: error,
              });
              return;
            }

            context.unicodePrintByVendorModalStore.handleOpen(rows.map((item) => item.cloudPmsPurchaseOrderId));
          },
        },
      ],
    },
    {
      permissionId: '45',
      text: '删除',
      handleClick: (e) => {
        context.onPurchaseOrderDeleteDetailClick(Array.from((context.programme.gridModel.subTablesModel.listModel)[0].gridModel.selectedIds));
      },
      icon: 'icon-btn_xj',
    },
    {
      permissionId: '6',
      text: '导出',
      icon: 'icon-export',
      handleClick: () => {
        const ids = values(context.programme.gridModel.gridModel.selectedIds)
          .join(',');
        const fileName = '采购单';
        const exportType = 'cloud_pms_purchase_order';
        const queryParam = context.programme.filterItems.params;
        const queryParamShow = context.programme.filterItems.translateParams.join(' ');

        if (ids) {
          context.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
        } else {
          Modal.confirm({
            title: '提示',
            content: '未选择数据将导出全部数据?',
            onOk: () => {
              context.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
            },
          });
        }
      },
    },
    {
      permissionId: '3022',
      text: '合并采购单',
      handleClick: (e) => {
        const rows: MainItem[] = context.programme.gridModel.gridModel.selectRows;

        if (rows.length <= 1) {
          const error = '请选择数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (!rows.every((item) => item.close == 0 && item.pushStatus == 0 && item.purchaseOrderStatus == 0)) {
          const error = '只能选择未关闭、未发布、未审核的采购单';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        Modal.confirm({
          content: '确定合并采购单吗?',
          onOk: async() => {
            await request({
              method: 'POST',
              url: '/api/cloud/pms/purchaseOrder/combinePurchase',
              data: { purchaseOrderIds: rows.map((item) => item.cloudPmsPurchaseOrderId) },
            });

            message.success('合并采购单成功');
            context.programme.gridModel.gridModel.resetAll();
            context.programme.gridModel.gridModel.onRefresh();
          },
        });
      },
    },
    {
      permissionId: '4058',
      text: '缺货统计',
      handleClick: (e) => {
        context.outOfStockStatisticsModalStore.handleOpen();
      },
    },
    {
      permissionId: '294',
      text: '生成应付单',
      icon: 'icon-audit',
      handleClick: () => {
        const selectRows: MainItem[] = context.programme.gridModel.gridModel.selectRows;
        if (!selectRows.length) {
          const error = '请至少选择一条数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (!selectRows.every((item) => item.purchaseOrderStatus != 0)) {
          const error = '只能选择已审核的采购单';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        Modal.confirm({
          content: '确认生成吗?',
          onOk: () => request({
            url: '/api/cloud/wms/rest/bill/other/generateBill',
            method: 'post',
            params: {
              otherStockOrderIds: selectRows.map((item) => item.cloudPmsPurchaseOrderId)
                .join(','),
              type: 2,
            },
          })
            .then(() => {
              context.programme.gridModel.onRefresh();
              message.success('生成成功');
            }),
        });
      },
    },
  ];
}
