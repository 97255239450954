import { Card, message, Modal } from 'antd';
import type { BaseData } from 'egenie-common';
import { getCustomPrintParam, request } from 'egenie-common';
import { MainSubStructure, MainSubStructureModel, NormalProgrammeComponent, NormalProgramme } from 'egenie-utils';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { pagePrint } from './mainButton';
import type { UnicodePrintByVendorItem } from './types';

interface UnicodePrintByVendorModalProps {
  unicodePrintByVendorModalStore: UnicodePrintByVendorModalStore;
  callback?: () => any;
}

let id = 0;

export class UnicodePrintByVendorModalStore {
  constructor() {
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
  }

  private purchaseOrderIds: string[] = [];

  @observable public visible = false;

  @action public handleOpen = (purchaseOrderIds: string[]) => {
    this.purchaseOrderIds = purchaseOrderIds;
    this.mainSubStructureModel.gridModel.resetAll();
    this.normalProgramme.reset();
    this.visible = true;
    this.mainSubStructureModel.onQuery();
  };

  @action public handleClose = () => {
    this.mainSubStructureModel.gridModel.resetAll();
    this.normalProgramme.reset();
    this.purchaseOrderIds = [];
    this.visible = false;
  };

  @action public handleOk = async() => {
    const selectMainRows: UnicodePrintByVendorItem[] = this.mainSubStructureModel.gridModel.selectRows;
    if (selectMainRows.length === 0) {
      message.error('请至少选择一条数据');
      return;
    }
    const purchaseOrderIds = new Set<string>();
    const purchaseOrderDetailIds = new Set<string>();
    const purchaseOrderVendorIds = new Set<string>();

    selectMainRows.forEach((item) => {
      if (item.purchaseOrderIds) {
        item.purchaseOrderIds.forEach((val) => {
          purchaseOrderIds.add(val);
        });
      }

      if (item.purchaseOrderDetailIds) {
        item.purchaseOrderDetailIds.forEach((val) => {
          purchaseOrderDetailIds.add(val);
        });
      }

      if (item.vendorId) {
        purchaseOrderVendorIds.add(item.vendorId);
      }
    });

    const {
      templateId,
      tempType,
      printer,
      preview,
    } = await getCustomPrintParam('27');
    await pagePrint({
      page: 1,
      pageSize: 1000,
      tempId: templateId,
      purchaseOrderIds: Array.from(purchaseOrderIds),
      purchaseOrderDetailIds: Array.from(purchaseOrderDetailIds),
      tempType,
      preview,
      printer,
      printByVendor: 1,
    });

    this.mainSubStructureModel.gridModel.resetAll();
    this.mainSubStructureModel.gridModel.onRefresh();
  };

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'vendorName',
          name: '供应商名称',
          width: 200,
        },
        {
          key: 'printSort',
          name: '打印排序',
          width: 100,
        },
        {
          key: 'planNum',
          name: '计划采购数量',
          width: 100,
        },
        {
          key: 'skuNum',
          name: 'sku数量',
          width: 80,
        },
        {
          key: 'printNum',
          name: '打印次数',
          width: 80,
        },
        {
          key: 'hasPrint',
          name: '打印状态',
          width: 80,
          formatter: ({ row }) => {
            return (
              <span>
                {row.hasPrint ? '已打印' : '未打印'}
              </span>
            );
          },
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: '_id',
      sortByLocal: false,
      showRefresh: false,
      showPager: false,
      showPagination: false,
      showCheckBox: true,
      showReset: true,
      showEmpty: false,
      pageSize: 200,
    },

    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;

        return request<BaseData<UnicodePrintByVendorItem[]>>({
          url: '/api/cloud/pms/print/queryVendorPrintData',
          method: 'POST',
          data: {
            ...filterParams,
            purchaseOrderIds: this.purchaseOrderIds,
          },
        })
          .then((info) => ({
            ...info,
            data: {
              ...info.data,
              list: (info.data || []).map((item) => ({
                ...item,
                _id: id++,
              })),
            },
          }));
      },
    },
    hiddenSubTable: true,
  });

  public normalProgramme: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'vendorName',
        label: '供应商名称',
      },
      {
        type: 'inputNumberGroup',
        field: 'startSort',
        label: '打印排序',
        minParamsField: 'startSort',
        maxParamsField: 'endSort',
      },
      {
        type: 'select',
        field: 'hasPrint',
        label: '打印状态',
        data: [
          {
            value: '1',
            label: '已打印',
          },
          {
            value: '0',
            label: '未打印',
          },
        ],
      },
    ],
    count: 4,
    handleSearch: () => this.mainSubStructureModel.onQuery(),
  });
}

@observer
export class UnicodePrintByVendorModal extends Component<UnicodePrintByVendorModalProps> {
  render() {
    const {
      unicodePrintByVendorModalStore: {
        handleClose,
        visible,
        normalProgramme,
        mainSubStructureModel,
        handleOk,
      },
      callback,
    } = this.props;
    return (
      <Modal
        centered
        destroyOnClose
        maskClosable={false}
        okText="打印条码"
        onCancel={() => {
          handleClose();
          if (typeof callback === 'function') {
            callback();
          }
        }}
        onOk={handleOk}
        title="按供应商打印条码"
        visible={visible}
        width={1400}
      >
        <Card size="small">
          <NormalProgrammeComponent store={normalProgramme}/>
        </Card>
        <div style={{ height: 540 }}>
          <MainSubStructure store={mainSubStructureModel}/>
        </div>
      </Modal>
    );
  }
}

