import { Button, message, Modal } from 'antd';
import type { BaseData } from 'egenie-common';
import { destroyAllModal, renderModal } from 'egenie-common';
import type { FilterConfigData, PaginationData } from 'egenie-utils';
import { formatFilterConfigData, MainSubStructureModel, Programme, request, ExportStore } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import { getAllVendor, getOwner, logSubGrid } from '../../../utils';
import { mainButton } from './mainButton';
import { mainColumns } from './mainColumns';
import { mainFilterItems } from './mainFilterItems';
import { OutOfStockStatisticsModalStore } from './outOfStockStatisticsModal';
import { ParamSettingModal } from './paramSettingModal';
import { purchaseOrderSkuDetail } from './purchaseOrderSkuDetail';
import { purchaseOrderVendorDetail } from './purchaseOrderVendorDetail';
import type { MainItem, ParamSetting } from './types';
import { UnicodePrintByVendorModalStore } from './unicodePrintByVendorModal';
import { UnicodeReprintModalStore } from './unicodeReprintModal';
import { UniqueCodeAndOrderDetailModel } from './uniqueCodeAndOrderDetail';
import { purchaseOrderParcelDetail } from './purchaseOrderParcelDetail';

export class Store {
  constructor() {
    this.init();
  }

  public exportStore: ExportStore = new ExportStore({ parent: null });

  public uniqueCodeAndOrderDetailModel = new UniqueCodeAndOrderDetailModel();

  public unicodeReprintModalStore = new UnicodeReprintModalStore();

  public unicodePrintByVendorModalStore = new UnicodePrintByVendorModalStore();

  public outOfStockStatisticsModalStore = new OutOfStockStatisticsModalStore(this);

  private init = () => {
    // 货主
    getOwner()
      .then((data) => {
        this.programme.filterItems.addDict({ ownerId: data });
        this.unicodeReprintModalStore.normalProgramme.filterItems.addDict({ ownerIds: data });
        this.outOfStockStatisticsModalStore.normalProgramme.filterItems.addDict({ ownerIds: data });
      });

    // 供应商
    getAllVendor()
      .then((data) => {
        this.programme.filterItems.addDict({ vendorId: data });
        this.unicodeReprintModalStore.normalProgramme.filterItems.addDict({ vendorIds: data });
        this.outOfStockStatisticsModalStore.normalProgramme.filterItems.addDict({ vendorIds: data });
      });

    request<FilterConfigData>({
      url: '/api/cloud/baseinfo/rest/filterSet2/getConfig2',
      params: {
        itemList: 'warehouse,employee,user',
        dictList: 'purchase_order_status,purchase_order_origin_type,cloud_purchase_order_type',
        module: '',
      },
    })
      .then((info) => {
        const list = formatFilterConfigData(info, {
          purchase_order_origin_type: 'purchaseOrderOriginType',
          purchase_order_status: 'purchaseOrderStatus',
          user: [
            'creator',
            'auditorId',
          ],
          warehouse: 'warehouseId',
          cloud_purchase_order_type: 'purchaseOrderType',
        });
        this.programme.filterItems.addDict(list.reduce((prev, current) => ({
          ...prev,
          [current.field]: current.data,
        }), {}));
      });

    this.getParamsSetting();
  };

  @observable public programme: Programme = new Programme({
    gridModel: new MainSubStructureModel({
      pageId: '2666',
      buttons: mainButton(this),
      btnExtraRight: (
        <Button onClick={() => {
          if (this.isLoadingParamsSetting) {
            return;
          }

          renderModal(
            <ParamSettingModal
              callback={(async(params) => {
                await request({
                  url: '/api/cloud/pms/purchaseOrder/config/edit',
                  method: 'post',
                  data: params,
                });

                message.success('设置成功');
                this.paramsSetting.autoCloseByOption = Boolean(params.autoCloseByOption);
                destroyAllModal();
              })}
              initialValues={{ autoCloseByOption: this.paramsSetting.autoCloseByOption }}
              onCancel={destroyAllModal}
            />
          );
        }}
        >
          参数设置
        </Button>
      ),
      grid: {
        // @ts-ignore
        columns: mainColumns(this),
        rows: [],
        primaryKeyField: 'cloudPmsPurchaseOrderId',
        sortByLocal: false,
        showCheckBox: true,
        showEmpty: true,
        setColumnsDisplay: true,
        gridIdForColumnConfig: '/egenie-cloud-wms/purchaseOrder/index/main',
      },

      // 主表查询api
      api: {
        onQuery: (params) => {
          const {
            filterParams,
            ...rest
          } = params;
          return request<PaginationData<MainItem>>({
            url: '/api/cloud/pms/purchaseOrder/listPageByParam',
            method: 'POST',
            data: {
              ...filterParams,
              ...rest,
            },
          });
        },
      },
      subTables: {
        activeTab: 'detail',
        tabsFlag: {
          inited: {
            detail: true,
            log: false,
          },
          searched: {},
        },
        list: [
          // @ts-ignore
          purchaseOrderSkuDetail(this),
          purchaseOrderVendorDetail(this),
          purchaseOrderParcelDetail(this),
          logSubGrid(50000),
        ],
      },
    }),
    filterItems: mainFilterItems(),
    moduleName: 'CloudPmsPurchaseOrder',
  });

  @action
  public onPurchaseOrderDeleteDetailClick = (cloudPmsPurchaseOrderDetailIds: string[]) => {
    if (cloudPmsPurchaseOrderDetailIds.length === 0) {
      message.warning({
        key: '请选择明细数据',
        content: '请选择明细数据',
      });
      return;
    }

    Modal.confirm({
      content: '确定删除吗',
      onOk: async() => {
        const req = await request<BaseData>({
          url: '/api/cloud/pms/purchaseOrderDetail/deleteDetail',
          method: 'POST',
          data: {
            cloudPmsPurchaseOrderId: this.programme.gridModel.gridModel.cursorRow.cloudPmsPurchaseOrderId,
            cloudPmsPurchaseOrderDetailIds,
          },
        });

        (this.programme.gridModel.subTablesModel.listModel)[0].onRefresh();
        this.programme.gridModel.gridModel.onRefresh();
        message.success(`${req.info}`);
      },
    });
  };

  @observable public paramsSetting: ParamSetting = { autoCloseByOption: false };

  @observable public isLoadingParamsSetting = false;

  @action public getParamsSetting = () => {
    this.isLoadingParamsSetting = true;
    request<BaseData<ParamSetting>>({ url: '/api/cloud/pms/purchaseOrder/config/query' })
      .then((info) => {
        if (info.data) {
          this.paramsSetting.autoCloseByOption = Boolean(info.data.autoCloseByOption);
        }
      })
      .finally(() => this.isLoadingParamsSetting = false);
  };
}
