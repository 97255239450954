import { Button, InputNumber, message } from 'antd';
import classnames from 'classnames';
import type { BaseData, PaginationData } from 'egenie-common';
import { toFixed, add, request } from 'egenie-common';
import { ImgFormatter, Permission } from 'egenie-utils';
import React from 'react';
import { enumPrintStatus } from './constants';
import styles from './index.less';
import type { Store } from './store';
import type { PurchaseSkuDetailItem } from './types';

export function purchaseOrderSkuDetail(context: Store) {
  return {
    filterItems: [
      {
        field: 'skuNo',
        label: 'sku编码',
        type: 'input',
      },
      {
        field: 'vendorName',
        label: '供应商名称',
        type: 'input',
      },
      {
        field: 'ownerName',
        label: '货主名称',
        type: 'input',
      },
      {
        field: 'erpPmsPurchaseOrderNo',
        label: 'erp采购单单号',
        type: 'input',
      },
    ],
    tab: {
      name: 'SKU明细',
      value: 'detail',
    },
    grid: {
      columns: [
        {
          key: 'operation',
          name: '操作',
          width: 120,
          formatter: ({ row }) => {
            const {
              gridModel: {
                cursorRow,
              },
            } = context.programme.gridModel;

            return (
              <div>
                {cursorRow.purchaseOrderStatus === 0 && (
                  <Permission permissionId="2666_1010">
                    <span
                      className={styles.egenieRowAction}
                      onClick={(event) => {
                        event.stopPropagation();
                        context.onPurchaseOrderDeleteDetailClick([row.cloudPmsPurchaseOrderDetailId]);
                      }}
                    >
                      删除
                    </span>
                  </Permission>
                )}
                <Button
                  className={styles.query}
                  onClick={() => context.uniqueCodeAndOrderDetailModel.handleOpen(row)}
                  type="link"
                >
                  查看
                </Button>
              </div>
            );
          },
        },
        {
          key: 'productName',
          name: '商品名称',
          width: 250,
        },
        {
          key: 'vendorName',
          name: '供应商',
          width: 250,
        },
        {
          key: 'ownerName',
          name: '货主',
          width: 250,
        },
        {
          key: 'vendorPrintStatus',
          name: '供应商打印',
          width: 80,
          formatter: ({ row }) => {
            return (
              <span>
                {row.vendorPrintStatus ? enumPrintStatus.yes.label : enumPrintStatus.no.label}
              </span>
            );
          },
        },
        {
          key: 'productNo',
          name: '商品编码',
          width: 250,
        },
        {
          key: 'picUrl',
          name: '图片',
          width: 50,
          formatter: ({ row }) => <ImgFormatter value={row.picUrl}/>,
        },
        {
          sortable: true,
          key: 'skuNo',
          name: 'SKU编码',
          width: 250,
          formatter: ({ row }) => {
            if (!row.skuNo) {
              return '';
            }
            return (
              <div className={styles.skuNo}>
                <span>
                  {row.skuNo}
                </span>
                <i
                  className={classnames('icon-sign_xiang', styles.iconSignXiang)}
                  onClick={() => context.uniqueCodeAndOrderDetailModel.handleOpen(row)}
                />
              </div>
            );
          },
        },
        {
          key: 'barcode',
          name: '条形码',
          width: 250,
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
        },
        {
          key: 'planPurchaseNum',
          sortable: true,
          name: '计划采购数量',
          width: 120,
          formatter: ({ row }) => {
            const {
              subTablesModel: {
                listModel,
              },
              gridModel: {
                cursorRow,
                onRefresh,
                resetAll,
              },
            } = context.programme.gridModel;
            if (cursorRow.purchaseOrderStatus !== 0) {
              return (
                <div className={styles.rowlr}>
                  {row.planPurchaseNum}
                </div>
              );
            }
            const editPlanPurchaseNum = (e) => {
              if (e.target.value === row.planPurchaseNum) {
                return;
              }
              request<BaseData<any>>({
                url: '/api/cloud/pms/purchaseOrderDetail/modifyDetailNum',
                method: 'POST',
                data: {
                  cloudPmsPurchaseOrderId: cursorRow.cloudPmsPurchaseOrderId,
                  cloudPmsPurchaseOrderDetailId: row.cloudPmsPurchaseOrderDetailId,
                  planPurchaseNum: Number(e.target.value),
                },
              })
                .then((req) => {
                  onRefresh();
                  listModel[0].onRefresh();
                  message.success(req.info);
                });
            };
            return (
              <div className={styles.rowlr}>
                <InputNumber
                  defaultValue={row.planPurchaseNum}
                  min={1}
                  onBlur={editPlanPurchaseNum}
                  onClick={(e) => e.stopPropagation()}
                  onPressEnter={editPlanPurchaseNum}
                />
              </div>
            );
          },
        },
        {
          key: 'vendorConfirmNum',
          name: '供应商确认数量',
          width: 120,
        },
        {
          key: 'arrivalNum',
          name: '收货数量',
          width: 80,
        },
        {
          key: 'onWayNum',
          name: '采购在途',
          width: 80,
        },
        {
          key: 'costPrice',
          name: '成本价',
          width: 80,
        },
        {
          key: 'planTotalPrice',
          name: '计划采购金额',
          width: 120,
        },
        {
          key: 'printTime',
          name: '打印次数',
          width: 80,
        },
        {
          key: 'erpPmsPurchaseOrderNo',
          name: 'erp采购单单号',
          width: 150,
        },
        {
          key: 'closeStatus',
          name: '关闭状态',
          width: 80,
          formatter: ({ row }: { row: PurchaseSkuDetailItem; }) => {
            return (
              <span>
                {row.closeStatus ? '已关闭' : '未关闭'}
              </span>
            );
          },
        },
        {
          key: 'pickGoodsNum',
          name: '拿货数量',
          width: 80,
        },
        {
          key: 'paymentOrderStatus',
          name: '应付单对账',
          width: 100,
        },
        {
          key: 'sendNum',
          name: '送货数量',
          width: 80,
        },
        {
          key: 'paymentNumber',
          name: '已生成应付单数量',
          width: 120,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'cloudPmsPurchaseOrderDetailId',
      sortByLocal: false,
      onSelectSum: false,
      sumColumns: [
        'planPurchaseNum',
        'arrivalNum',
      ],
      searchReduce: true,
      searchReduceConfig: [
        {
          name: '计划采购金额',
          value: 0,
        },
      ],
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/purchaseOrder/index/skuSub',
    },
    api: {
      onQuery: ({
        data,
        pid,
      }) => {
        const {
          sidx,
          sord,
          page,
          pageSize,
          cond,
        } = data;
        return request<PaginationData<PurchaseSkuDetailItem>>({
          url: '/api/cloud/pms/purchaseOrderDetail/queryDetail',
          method: 'POST',
          data: {
            ...cond,
            sidx,
            sord,
            page,
            pageSize,
            cloudPmsPurchaseOrderId: pid,
          },
        })
          .then((info) => {
            const list = info.data?.list || [];
            context.programme.gridModel.subTablesModel.listModel.find((item) => item.tab.value === 'detail').gridModel.searchReduceConfig[0].value = toFixed(list.reduce((prev, current) => add(prev, current.planTotalPrice), 0), 2);
            return {
              ...info,
              data: {
                list,
                ...info.data,
              },
            };
          });
      },
    },
  };
}
