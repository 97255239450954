export const enumPushStatus = {
  yes: {
    value: '1',
    label: '已发布',
  },
  no: {
    value: '0',
    label: '未发布',
  },
};

export const enumPrintStatus = {
  yes: {
    value: '1',
    label: '是',
  },
  no: {
    value: '0',
    label: '否',
  },
};
