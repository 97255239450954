import { request } from 'egenie-common';
import React from 'react';
import { enumPrintStatus } from './constants';
import type { Store } from './store';

export function purchaseOrderVendorDetail(context: Store) {
  return {
    tab: {
      name: '供应商维度',
      value: 'vendor',
    },
    grid: {
      columns: [
        {
          key: 'vendorName',
          name: '供应商',
          width: 200,
        },
        {
          key: 'vendorPrintStatus',
          name: '供应商打印',
          width: 100,
          formatter: ({ row }) => {
            return (
              <span>
                {row.vendorPrintStatus ? enumPrintStatus.yes.label : enumPrintStatus.no.label}
              </span>
            );
          },
        },
        {
          key: 'planPurchaseNum',
          name: '计划采购总数',
          width: 120,
        },
        {
          key: 'totalPrice',
          name: '计划采购总金额',
          width: 120,
        },
        {
          key: 'vendorConfirmNum',
          name: '供应商确认数量',
          width: 120,
        },
        {
          key: 'printTime',
          name: '打印条码次数',
          width: 120,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'cloudPmsPurchaseOrderVendorId',
      sortByLocal: false,
      showPager: false,
    },
    api: {
      onQuery: ({ pid }) => {
        return request({ url: `/api/cloud/pms/purchaseOrderVendor/query/${pid}` });
      },
    },
  };
}
