import { Form, Modal, Select } from 'antd';
import type { PaginationData, ValueAndLabelData } from 'egenie-utils';
import { request } from 'egenie-utils';
import React from 'react';

interface ProducePurchaseOrderProps {
  callback: (params: { ownerIds: string[]; }) => Promise<unknown>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onCancel: Function;
  title: string;
}

export function ProducePurchaseOrder({
  callback,
  onCancel,
  title,
}: ProducePurchaseOrderProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState<{ loading: boolean; params: { ownerIds: string[]; }; }>({
    loading: false,
    params: null,
  });

  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  const [
    ownerList,
    setOwnerList,
  ] = React.useState<ValueAndLabelData>([]);

  React.useEffect(() => {
    request<PaginationData<{ ownerUniqueName: string; ownerId: string | number; }>>({
      method: 'POST',
      url: '/api/cloud/baseinfo/rest/owner/queryOwnerReal',
      data: {
        enable: 1,
        page: 1,
        pageSize: 10000,
      },
    })
      .then((info) => {
        setOwnerList((info.data?.list || []).map((item) => ({
          value: `${item.ownerId}`,
          label: item.ownerUniqueName,
        })));
      });
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback(paramData.params)
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      maskClosable={false}
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title={title}
      visible
      width={400}
    >
      <Form
        form={form}
        initialValues={{ ownerIds: []}}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="货主"
          name="ownerIds"
          required
          rules={[
            {
              required: true,
              message: '请选择货主',
            },
          ]}
        >
          <Select
            allowClear
            maxTagCount={3}
            mode="multiple"
            notFoundContent="没有数据"
            optionFilterProp="label"
            options={ownerList}
            placeholder="请选择货主"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
