import { Input, message, Modal } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { Permission, TimeStampFormatter } from 'egenie-utils';
import React from 'react';
import { enumPushStatus } from './constants';
import styles from './index.less';
import type { Store } from './store';
import type { MainItem } from './types';

function getProgressValue(status): { progressValue: number; color: React.CSSProperties; value: string; } {
  switch (status) {
    case 0: {
      return {
        progressValue: 25,
        color: styles.bgb,
        value: '待审核',
      };
    }
    case 1: {
      return {
        progressValue: 50,
        color: styles.bgb,
        value: '待收货',
      };
    }
    case 2: {
      return {
        progressValue: 75,
        color: styles.bgb,
        value: '收货中',
      };
    }
    case 3: {
      return {
        progressValue: 100,
        color: styles.bgg,
        value: '已关闭',
      };
    }
    default: {
      return {
        progressValue: 0,
        color: styles.bgg,
        value: '',
      };
    }
  }
}

export function mainColumns(context: Store) {
  return [
    {
      key: 'operate',
      name: '操作',
      width: 150,
      frozen: true,
      formatter: ({ row }) => {
        if (row.purchaseOrderStatus === 0) {
          return (
            <Permission permissionId="2666_45">
              <span
                className={styles.egenieRowAction}
                onClick={(e) => {
                  e.stopPropagation();
                  Modal.confirm({
                    content: '确定删除吗',
                    onOk: async() => {
                      const req = await request<BaseData>({
                        url: '/api/cloud/pms/purchaseOrder/delete',
                        method: 'POST',
                        data: { cloudPmsPurchaseOrderId: row.cloudPmsPurchaseOrderId },
                      });

                      context.programme.gridModel.gridModel.resetAll();
                      context.programme.gridModel.gridModel.onRefresh();
                      message.success(`${req.info}`);
                    },
                  });
                }}
              >
                删除
              </span>
            </Permission>
          );
        } else {
          return null;
        }
      },
    },
    {
      key: 'cloudPmsPurchaseOrderNo',
      name: '采购单号',
      sortable: true,
      width: 200,
    },
    {
      key: 'erpPmsPurchaseOrderNo',
      name: '原采购单号',
      width: 200,
    },
    {
      key: 'purchaseOrderTypeStr',
      name: '采购单类型',
      width: 100,
    },
    {
      key: 'warehouseName',
      name: '仓库',
      width: 200,
    },
    {
      key: 'vendorName',
      name: '供应商',
      width: 200,
    },
    {
      key: 'pushStatus',
      name: '发布供应商',
      width: 80,
      formatter: ({ row }: { row: MainItem; }) => {
        return (
          <span>
            {row.pushStatus ? enumPushStatus.yes.label : enumPushStatus.no.label}
          </span>
        );
      },
    },
    {
      key: 'purchaseOrderStatus',
      name: '单据状态',
      width: 150,
      formatter: ({ row }) => {
        const {
          progressValue,
          color,
          value,
        } = getProgressValue(row.purchaseOrderStatus);
        return (
          <div className={styles.rowProgressWrap}>
            <div className={styles.progressTop}>
              {value}
            </div>
            <progress
              className={`${styles.progress} ${color}`}
              max={100}
              style={{ width: '70%' }}
              value={progressValue}
            />
          </div>
        );
      },
    },
    {
      key: 'closeStatus',
      name: '关闭状态',
      width: 80,
    },
    {
      key: 'orderOriginType',
      name: '采购来源',
      width: 100,
    },
    {
      key: 'totalNum',
      sortable: true,
      name: '总数量',
      width: 80,
    },
    {
      key: 'totalPrice',
      name: '总金额',
      sortable: true,
      width: 80,
    },
    {
      key: 'remarks',
      name: '备注',
      width: 150,
      editable: true,
      formatter: ({ row }) => {
        const { cloudPmsPurchaseOrderId } = row;
        const editRemark = (e) => {
          if (e.target.value === row.remarks) {
            return;
          }
          request<BaseData<any>>({
            url: '/api/cloud/pms/purchaseOrder/modifyRemark',
            method: 'POST',
            data: {
              cloudPmsPurchaseOrderId,
              remarks: e.target.value,
            },
          })
            .then((req) => {
              message.success(req.info);
            });
        };

        return (
          <Input
            className={styles.remark}
            defaultValue={row.remarks}
            maxLength={25}
            onBlur={editRemark}
            onClick={(e) => e.stopPropagation()}
            onPressEnter={editRemark}
            size="small"
          />
        );
      },
    },

    {
      key: 'creatorName',
      name: '创建人',
      width: 150,
    },
    {
      key: 'createTime',
      name: '创建时间',
      width: 150,
      sortable: true,
      formatter: ({ row }) => {
        return <TimeStampFormatter value={row.createTime}/>;
      },
    },
    {
      key: 'auditorName',
      name: '审核人',
      width: 150,
    },
    {
      key: 'checkTime',
      name: '审核时间',
      width: 150,
      sortable: true,
      formatter: ({ row }) => {
        return <TimeStampFormatter value={row.checkTime}/>;
      },
    },
    {
      key: 'closeTime',
      name: '关闭时间',
      width: 150,
      sortable: true,
      formatter: ({ row }) => {
        return <TimeStampFormatter value={row.closeTime}/>;
      },
    },
    {
      key: 'ownerUniqueName',
      name: '货主',
      width: 150,
    },
    {
      key: 'printTime',
      name: '打印次数',
      width: 80,
    },
    {
      key: 'paymentOrderStatus',
      name: '应付单对账',
      width: 100,
    },
  ].map((v) => ({
    resizable: true,
    sortable: false,
    ...v,
  }));
}
