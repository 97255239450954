import { ExportModal, ProgrammeComponent } from 'egenie-utils';
import { Provider } from 'mobx-react';
import React from 'react';
import { OutOfStockStatisticsModal } from './outOfStockStatisticsModal';
import { Store } from './store';
import { UnicodePrintByVendorModal } from './unicodePrintByVendorModal';
import { UnicodeReprintModal } from './unicodeReprintModal';
import { UniqueCodeAndOrderDetailModal } from './uniqueCodeAndOrderDetail';

export default function() {
  const store = React.useMemo(() => new Store(), []);
  const {
    programme,
    exportStore,
    unicodeReprintModalStore,
    unicodePrintByVendorModalStore,
    outOfStockStatisticsModalStore,
  } = store;

  return (
    <Provider store={store}>
      <ProgrammeComponent store={programme}/>
      <UniqueCodeAndOrderDetailModal store={store}/>
      <ExportModal store={exportStore}/>
      <UnicodeReprintModal unicodeReprintStore={unicodeReprintModalStore}/>
      <UnicodePrintByVendorModal
        callback={programme.gridModel.onRefresh}
        unicodePrintByVendorModalStore={unicodePrintByVendorModalStore}
      />
      <OutOfStockStatisticsModal outOfStockStatisticsModalStore={outOfStockStatisticsModalStore}/>
    </Provider>
  );
}
